/**
 * common.js
 * @theme kimpembe
 * @version 0.1
 */
 $(() => {
  //ハンバーガーアイコン
  $('#btn').on('click', function () {
    $('#btn__top').toggleClass('rotate-top')
    $('#btn__middle').toggleClass('hide-middle')
    $('#btn__bottom').toggleClass('rotate-bottom')
    $('#gnav').toggleClass('nav-active')
  });

  // message-box close
  $(document).on("click", ".message-box > .close", function() {
    $(this).parent().animate({'height': 0}, 300, function() {
    $(this).remove();
    });
  });
});